import axiosClient from "@/services/api/index";
import { CreateProfileDto, KlaviyoProfile, CreateEventDto, VerifyProfileDto, ValidatePhoneNumberDto } from "@/types/KlaviyoInterface";
import { HttpResponseType, VerifyProfileResponseType } from "@/types/HttpResponseType";

export default {
  async createProfile(params: CreateProfileDto): Promise<KlaviyoProfile> {
    return axiosClient.post('klaviyo/profile', params)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  },
  async verifyProfile(params: VerifyProfileDto): Promise<VerifyProfileResponseType> {
    return axiosClient.get('klaviyo/profile/verify', { params })
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  },
  async createProfileAndSendVerificationSms(params: CreateProfileDto): Promise<KlaviyoProfile> {
    return axiosClient.post('klaviyo/profile/verification', params)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  },
  async getProfileById(id: string): Promise<KlaviyoProfile> {
    return axiosClient.get(`klaviyo/profile/${id}`)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  },
  async createEvent(params: CreateEventDto): Promise<HttpResponseType> {
    return axiosClient.post('klaviyo/event', params)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  },
  async findProfileByQuery(): Promise<KlaviyoProfile[]> {
    return axiosClient.get('klaviyo/profile')
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  },
  async validatePhoneNumber(params: ValidatePhoneNumberDto): Promise<HttpResponseType> {
    return axiosClient.post('klaviyo/profile/validate-phone', params)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  }
};
