import { reactive } from "vue";
import { Module } from "vuex";
import { AxiosResponse } from "axios";
import { BuilderCreateType, BuilderInterface } from "@/types/BuilderInterface";
import builderApi from "@/services/api/builderApi";
import { CreateBuilderQuote } from "@/types/CreateBuilderQuote";
import {
  HouseDesignCreateType,
  HouseDesignInterface,
} from "@/types/HouseDesignInterface";
import {
  checkObjectHasEmptyValues,
  checkObjectHasEmptyValuesAllowingZero,
} from "@/helpers/checkObjectHasEmptyValues";
import { UserQuoteSelectionInterface } from "@/types/UserQuoteSelectionInterface";
import { userSelectionDefault } from "@/constants/BuilderUserSelectionDefault";
import { CustomizeHouseStepsEnum } from "@/enums/CustomizeHouseSteps";
import { BuilderQuote } from "@/types/BuilderQuote";
import { HouseFiltersFields } from "@/types/HouseFilterFieldsInterface";

const state = reactive({
  userSelectionDesign: [userSelectionDefault],
});

export type builderState = typeof state;

const builderStore: Module<builderState, never> = {
  namespaced: true,
  state,
  getters: {
    hasUserSelectionDesignEmptyValues({ userSelectionDesign }: builderState) {
      if (!userSelectionDesign.length) {
        return true;
      }
      const isEmpty = userSelectionDesign.map((selection) => {
        const { facade, builder, house, floorplan } = selection;

        const isAnySectionEmpty = [facade, { url: floorplan.url }].some(
          checkObjectHasEmptyValuesAllowingZero
        );
        const areBuilderAndDesignIdsPresent = !!builder.id && !!house.id;
        return !isAnySectionEmpty && areBuilderAndDesignIdsPresent;
      });
      return isEmpty.includes(false);
    },

    getSelectedSelection({ userSelectionDesign }: builderState) {
      return userSelectionDesign.find((selection) => selection.isSelected);
    },
  },
  mutations: {
    updateUserSelection(
      builderState: builderState,
      payload: UserQuoteSelectionInterface
    ): void {
      const updatedUserSelection = builderState?.userSelectionDesign.map(
        (userSelection) => {
          const { isSelected, id } = userSelection;
          return isSelected && id === payload.id ? payload : userSelection;
        }
      );
      builderState.userSelectionDesign = updatedUserSelection;
    },
    clearUserSelection(builderState: builderState): void {
      builderState.userSelectionDesign = [userSelectionDefault];
    },
    addUserSelection(builderState: builderState): void {
      builderState.userSelectionDesign.forEach((userSelection) => {
        userSelection.isSelected = false;
      });
      builderState.userSelectionDesign = [
        ...builderState.userSelectionDesign,
        {
          ...userSelectionDefault,
          id: builderState.userSelectionDesign.length + Date.now(),
          isSelected: true,
        },
      ];
    },

    setActiveSelection(
      builderState: builderState,
      payload: UserQuoteSelectionInterface
    ): void {
      builderState.userSelectionDesign.forEach((userSelection) => {
        userSelection.isSelected = payload.id === userSelection.id;
      });
    },

    deleteSelection(builderState: builderState, payload: number): void {
      const deleteSelection = builderState.userSelectionDesign.filter(
        (selection) => {
          return selection.id !== payload;
        }
      );
      if (deleteSelection.length === 1) {
        deleteSelection[0].isSelected = true;
      }
      builderState.userSelectionDesign = deleteSelection;
    },
    setCurrentStep(
      builderState: builderState,
      payload: CustomizeHouseStepsEnum
    ) {
      builderState.userSelectionDesign = builderState.userSelectionDesign.map(
        (selection) => {
          return selection.isSelected
            ? {
                ...selection,
                currentStep: payload,
              }
            : selection;
        }
      );
    },
    updateCurrentStepForAllQuotes(builderState: builderState) {
      const { userSelectionDesign } = builderState;
      const areAllStepsExterior = userSelectionDesign.every(
        (selection) =>
          selection.currentStep === CustomizeHouseStepsEnum.EXTERIOR
      );
      if (areAllStepsExterior) {
        builderState.userSelectionDesign = userSelectionDesign.map(
          (selection) => ({
            ...selection,
            currentStep: CustomizeHouseStepsEnum.CONFIRMATION,
          })
        );
      }
    },
  },
  actions: {
    getBuildersByLotId(_, id: string): Promise<AxiosResponse> {
      return builderApi.getBuildersByLotId(id);
    },

    getBuilders(
      _,
      query: { name: string } = { name: "" }
    ): Promise<AxiosResponse> {
      return builderApi.getBuilders(query);
    },
    createBuilder(_, builder: BuilderCreateType): Promise<AxiosResponse> {
      return builderApi.createBuilder(builder);
    },
    uploadLogo(_, logoData: FormData): Promise<AxiosResponse> {
      return builderApi.uploadLogo(logoData);
    },
    getBuilderById(_, id: string): Promise<AxiosResponse> {
      return builderApi.getBuilderById(id);
    },
    updateBuilder(_, builder: BuilderInterface): Promise<AxiosResponse> {
      return builderApi.updateBuilder(builder);
    },
    getBuilderWithHouses(_, builderId: string): Promise<AxiosResponse> {
      return builderApi.getBuilderWithHouses(builderId);
    },
    getAllBuilderWithHouses(): Promise<AxiosResponse> {
      return builderApi.getAllBuilderWithHouses();
    },
    getSiteCost(
      _,
      {
        lotId,
        builderId,
        houseId,
      }: { lotId: string; builderId: string; houseId: string }
    ): Promise<AxiosResponse> {
      return builderApi.getSiteCost(lotId, builderId, houseId);
    },
    deleteBuilder(_, builderId: string): Promise<AxiosResponse> {
      return builderApi.deleteBuilder(builderId);
    },
    getHousesByBuilderId(
      _,
      params: { builderId: string; name: string }
    ): Promise<AxiosResponse> {
      return builderApi.getHousesByBuilderId(params);
    },
    deleteHouse(_, houseId: string): Promise<AxiosResponse> {
      return builderApi.deleteHouse(houseId);
    },
    sendQuote(_, data: CreateBuilderQuote): Promise<AxiosResponse> {
      return builderApi.sendQuote(data);
    },
    securePackage(
      _,
      {
        quoteId,
        data,
      }: {
        quoteId: string;
        data: CreateBuilderQuote;
      }
    ): Promise<AxiosResponse> {
      return builderApi.securePackage(quoteId, data);
    },
    setAsOpened(_, id: string): Promise<AxiosResponse> {
      return builderApi.setAsOpened(id);
    },
    getQuote(_, id: string): Promise<AxiosResponse> {
      return builderApi.getQuote(id);
    },
    getQuoteWithDetails(_, id: string): Promise<AxiosResponse> {
      return builderApi.getQuoteWithDetails(id);
    },
    getPackages(
      _,
      { estateId }: { estateId: string | null }
    ): Promise<AxiosResponse> {
      return builderApi.getPackages(estateId);
    },
    updatePackage(
      _,
      { quoteId, data }: { quoteId: string; data: BuilderQuote }
    ): Promise<AxiosResponse> {
      return builderApi.updatePackage(quoteId, data);
    },
    deletePackage(_, packageId: string): Promise<AxiosResponse> {
      return builderApi.deletePackage(packageId);
    },
    uploadHouseImages(
      _,
      { formData, builderId }: { formData: FormData; builderId: string }
    ): Promise<AxiosResponse> {
      return builderApi.uploadHouseImages(formData, builderId);
    },
    uploadHouseFile(
      _,
      { formData, builderId }: { formData: FormData; builderId: string }
    ): Promise<AxiosResponse> {
      return builderApi.uploadHouseFile(formData, builderId);
    },
    createHouse(_, house: HouseDesignCreateType): Promise<AxiosResponse> {
      return builderApi.createHouse(house);
    },
    getHousesByFilter(
      _,
      {
        estateId,
        query,
      }: {
        estateId: string;
        query: HouseFiltersFields;
      }
    ): Promise<AxiosResponse> {
      return builderApi.getHousesByFilter(estateId, query);
    },
    getQuantitysBedroomsBathroomsStoreys(): Promise<AxiosResponse> {
      return builderApi.getQuantitysBedroomsBathroomsStoreys();
    },
    getLotsByEstateAndHouseDesign(
      _,
      { estateId, houseId }: { estateId: string; houseId: string }
    ): Promise<AxiosResponse> {
      return builderApi.getLotsByEstateAndHouseDesign(estateId, houseId);
    },
    getHouseById(_, houseId: string): Promise<AxiosResponse> {
      return builderApi.getHouseById(houseId);
    },
    updateHouse(_, house: HouseDesignInterface): Promise<AxiosResponse> {
      return builderApi.updateHouse(house);
    },
    bulkHouseUpload(
      _,
      payload: { builderId: string; data: FormData }
    ): Promise<AxiosResponse> {
      return builderApi.bulkHouseUpload(payload);
    },
  },
};

export default builderStore;
